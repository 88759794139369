import fr from './fr'
import en from './en'
import es from './es'
import de from './de'
import tw from './tw'
import jp from './jp'
import zh from './zh'
import pr from './pr'

export default {
	fr,
	en,
	es,
	de,
	tw,
	jp,
	zh,
	pr,
}
